import React, { useEffect, useState }  from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import "./../App.css";
import robot from './../images/android-chrome-192x192.png';

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({ hitType: "pageview", page: "/logout", title: "Logout Page" });

      signOut(auth).then(() => {
        navigate("/");
        console.log("Signed out successfully")
      }).catch((error) => {
        console.log(error);
      });

  }, [])

  return (
    <div className="App">
      <header className="Content-header">
        <div className="container">
          Logged out...
        </div>
      </header>
    </div>
  )
}

export default Logout;
