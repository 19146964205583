import React from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import "./../App.css";
import robot from './../images/android-chrome-192x192.png';

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

function Home() {

  React.useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: "/home", title: "Home Page" });
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <center>
                <h1 className="brand">ROBOTICS.DEV</h1>
                <p>AI Robotics Developer Platform</p>
                <p>
                  <NavLink to="/signup">
                    <button className="btn btn-outline-light btn-lg">
                      SignUp
                    </button>
                  </NavLink>
                  &nbsp;
                  <NavLink to="/login">
                    <button className="btn btn-outline-light btn-lg">
                      Login
                    </button>
                  </NavLink>
                </p>
              </center>
            </div>
            <div className="col-sm-6">
              <center><img src={robot} alt="robot"/></center>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Home;
