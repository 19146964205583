import React, { useEffect, useState }  from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { addDoc, getDoc, getDocs, doc, setDoc, deleteDoc, updateDoc, deleteField, query, collection, where } from "firebase/firestore";

import { Joystick } from 'react-joystick-component-godofkim';

import io from 'socket.io-client';
// import mqtt from "mqtt";

import "./../App.css";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

// const Jimp = require("jimp");
// import Jimp from "jimp";

const Control = ({ socket }) => {

  const [speed, setSpeed] = useState(20);
  const [lastStick, setLastStick] = useState(null);
  const [uid, setUid] = useState('');
  const [robot, setRobot] = useState('');
  const [robots, setRobots] = useState([]);
  const [rgbStream, setRgbStream] = useState('');
  // var mqttClient;

  async function getRobots(userid){
    const q = query(collection(db, "robots"), where("uid", "==", userid));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size > 0){
      let robotList = [];
      querySnapshot.forEach((doc) => {
        var robotX = doc.data();
        robotX.id = doc.id;
        robotList.push(robotX);
      });
      setRobots(robotList);
      setRobot(robotList[0].id)
    } else {
      setRobots([]);
    }
  }

  // async function convertFrameToBase64(buffer, width, height) {
  // 	const image = new Jimp(width, height)
  // 	for (let y=0; y < height; y++) {
  // 		for (let x=0; x < width; x++) {
  // 			const index = (y * width + x) * 3;
  // 			const r = buffer[index];
  // 			const g = buffer[index + 1];
  // 			const b = buffer[index + 2];
  // 			const color = Jimp.rgbaToInt(r, g, b, 255);
  // 			image.setPixelColor(color, x, y);
  // 		}
  // 	}
  //
  // 	const base64String = await image.getBase64Async(Jimp.MIME_PNG);
  // 	return base64String;
  // }

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({ hitType: "pageview", page: "/control", title: "Control Page" });

      onAuthStateChanged(auth, (user) => {
          if (user) {
            setUid(user.uid);
            getRobots(user.uid);
          } else {
            console.log("user is logged out")
          }
        });

        // connect to socket.io
        var socket
        if (process.env.NODE_ENV === 'development') {
          socket = io('http://localhost:3000');
        }
        if (process.env.NODE_ENV === 'production') {
          socket = io('https://robotics.dev');
        }
        socket.on("connect", () => {
          console.log("socketId:", socket.id); // x8WIv7-mJelg7on_ALbx
        });
        socket.on("d013791b-676e-43de-9808-df61dc36a4bc/color/image_raw", (message) => {
          // console.log(message);
          setRgbStream(message);
          // convertFrameToBase64(message.data, message.width, message.height)
          // 	.then(base64 => {
          // 	   	// var frameData = {};
          // 		// frameData.robot_id = robotId;
          //     		// frameData.image = base64;
          // //		socket.emit('color/image_raw', frameData);
          // 		// mqttClient.publish(robotId + '/color/image_raw', base64);
          //     setRgbStream(base64)
          // 	})
        });

  }, [])

  // useEffect(() => {
  //   if(robot != ''){
  //     // connect to Mqtt
  //     mqttClient = mqtt.connect("wss://nl4ium.stackhero-network.com/", {
  //         username: "robotics",
  //         password: "e1bztwPlZLxzznSq2JKCG7tJNNzdWC8v",
  //         keepAlive: 65535,
  //         cleanSession: false
  //     });
  //
  //     mqttClient.on('connect', () => {
  //       console.log('MQTT: connected');
  //       mqttClient.subscribe(robot + '/color/image_raw');
  //       console.log('mqtt subscribed to', robot + '/color/image_raw');
  //     });
  //
  //     mqttClient.on('error', (err) => {
  //       console.error('Connection error: ', err);
  //     });
  //
  //     // mqttClient.subscribe(robot + '/color/image_raw', (err) => {
  //     //   if (!err) {
  //     //     // client.publish("presence", "Hello mqtt");
  //     //     console.log('mqtt subscribed to', robot + '/color/image_raw');
  //     //   }
  //     // });
  //     mqttClient.on("message", (topic, message) => {
  //       // message is Buffer
  //       // console.log(JSON.stringify(message));
  //       // console.log(message);
  //       // client.end();
  //       // setRgbStream(message.toString())
  //
  //       // convertFrameToBase64(message.data, message.width, message.height)
  //       // 	.then(base64 => {
  //       // 	   	// var frameData = {};
  //       // 		// frameData.robot_id = robotId;
  //       //     		// frameData.image = base64;
  //       // //		socket.emit('color/image_raw', frameData);
  //       // 		// mqttClient.publish(robotId + '/color/image_raw', base64);
  //       //     setRgbStream(base64)
  //       // 	})
  //
  //     });
  //   }
  // }, [robot])

  const joy = (stick) => {
    if(stick.direction === lastStick) return;
    console.log(stick);
    var twistMsg;
    if(stick.type === 'stop'){
      twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}}
    } else if (stick.direction === 'FORWARD') {
      twistMsg = {linear: {x: speed/100, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}}
    } else if (stick.direction === 'BACKWARD') {
      twistMsg = {linear: {x: -speed/100, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}}
    } else if (stick.direction === 'LEFT') {
      twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: speed/100}}
    } else if (stick.direction === 'RIGHT') {
      twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: -speed/100}}
    }
    setLastStick(stick.direction);
    fetch("/twist", {
      method: "POST",
      body: JSON.stringify({ robot: robot, twist: twistMsg }),
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then((res) => res.json())
    // .then((output) => setOutput(''));
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center">
                {rgbStream ? <img src={rgbStream} width="424" height="240"/>: ''}
              </div>
              <div className="d-flex justify-content-center">
                <Joystick className="" size={100} sticky={false} baseColor="gray" stickColor="#bd93f9" move={joy} stop={joy}></Joystick>
              </div>
              <label htmlFor="customRange1" className="form-label">Speed</label>
              <input type="range" className="form-range" id="customRange1" min="0" max="100" value={speed} step="5" onChange={e => setSpeed(e.target.value)}/>
              {robots.length === 0 ? "No robots created yet..." :
              <select onChange={e => setRobot(e.target.value)} className="form-select">
                {robots.map((robot, index) => (
                  <option key={index} value={robot.id}>{robot.name}</option>
                ))}
              </select>
              }
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Control;
