import React, { useEffect, useState }  from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { addDoc, getDoc, getDocs, doc, setDoc, deleteDoc, updateDoc, deleteField, query, collection, where } from "firebase/firestore";

import { LiveProvider, LiveEditor } from "react-live";
import { themes } from "prism-react-renderer";
import "./../App.css";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

function Copilot() {
  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState(null);
  const [output, setOutput] = useState(null);
  const [postContent, setPostContent] = useState('');
  const [postRos, setPostRos] = useState('ROS2');
  const [postCamera, setPostCamera] = useState('none');
  const [postType, setPostType] = useState('AMR');
  const [postWheels, setPostWheels] = useState(0);
  const [postWheelDiameter, setPostWheelDiameter] = useState(0);
  const [postWheelWidth, setPostWheelWidth] = useState(0);
  const [postWheelBetween, setPostWheelBetween] = useState(0);
  const [uid, setUid] = useState('');
  const [robot, setRobot] = useState('');
  const [robots, setRobots] = useState([]);

  async function getRobots(userid){
    const q = query(collection(db, "robots"), where("uid", "==", userid));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size > 0){
      let robotList = [];
      querySnapshot.forEach((doc) => {
        var robotX = doc.data();
        robotX.id = doc.id;
        robotList.push(robotX);
      });
      setRobots(robotList);
      setRobot(robotList[0].id)
    } else {
      setRobots([]);
    }
  }


  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: "/copilot", title: "Copilot Page" });

      onAuthStateChanged(auth, (user) => {
          if (user) {
            setUid(user.uid);
            getRobots(user.uid);
          } else {
            console.log("user is logged out")
          }
        });

  }, [])

  const callGpt = () => {
    // setData("Beep Boop...");
    setSpinner(true);
    fetch("/gpt", {
      method: "POST",
      body: JSON.stringify({ content: postContent, ros: postRos, camera: postCamera, type: postType, wheels: postWheels, wheelDiameter: postWheelDiameter, wheelWidth: postWheelWidth, wheelBetween: postWheelBetween }),
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then((res) => res.json())
    .then((data) => setData(data.message));
  };

  const executeGpt = () => {
    console.log("executeGpt");
    fetch("/python", {
      method: "POST",
      body: JSON.stringify({ robot: robot, content: data }),
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then((res) => res.json())
    .then((output) => setOutput(output.message));
  };

  const handleRobotId = bot => {
    // console.log(bot.target.value);
    // setRobot(bot.target.value);
    for (var i = 0, l = robots.length; i < l; i++) {
      if(robots[i].id === bot.target.value){
        // console.log(robots[i]);
        setRobot(robots[i].id);
        setPostType(robots[i].type);
        setPostRos(robots[i].ros);
        setPostCamera(robots[i].camera);
        setPostWheels(robots[i].wheelCount);
        setPostWheelDiameter(robots[i].wheelDiameter);
        setPostWheelWidth(robots[i].wheelWidth);
        setPostWheelBetween(robots[i].wheelBetween);
      }
    }
  };

  const handleBack = () => {
    setSpinner(false);
    setData(null);
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <div className="row">
          {data == null ?
            <div className="col-sm-12">
              <div className="mb-3">

                {robots.length === 0 ? <p>No robots created yet...</p> :
                <select onChange={handleRobotId} className="form-select">
                  {robots.map((robot, index) => (
                    <option key={index} value={robot.id}>{robot.name}</option>
                  ))}
                </select>
                }

                <textarea className="form-control" id="robotDesc" rows="5"
                  value={postContent}
                  onChange={e => setPostContent(e.target.value)}
                  placeholder="Describe your robotic actions."
                />

                <button type="button" className="btn btn-outline-light btn-lg" onClick={callGpt}>Generate Code</button>

                {spinner ?
                  <div className="d-flex align-items-center">
                    <div className="spinner-border ms-auto" aria-hidden="true"></div>
                  </div>
                : "" }

                <br/>
                {output == null? "" :
                  output
                }
                <br/>
              </div>
            </div>
            :
            <div className="col-sm-12">
              <button type="button" className="btn btn-outline-light" onClick={handleBack}> &lt; Back</button>
              <button type="button" className="btn btn-primary" onClick={executeGpt}>Deploy & Execute &gt; </button>
              <LiveProvider code={data} language='python' theme={themes.dracula}>
                <LiveEditor onChange={newCode => { setData(newCode) }}/>
              </LiveProvider>


              <br/><br/>
            </div>
            }
          </div>
        </div>
      </header>
    </div>
  );
}

export default Copilot;
