import React, {useState} from 'react';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../firebase';
import { NavLink, useNavigate } from 'react-router-dom'
import "./../App.css";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [feedback, setFeedback] = useState('');

    React.useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: "/login", title: "Login Page" });
    }, [])

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            // console.log(user);
            navigate("/main")
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            setFeedback("Invalid user credentials");
        });
    }

    return(
      <div className="App">
        <header className="App-header">
          <div className="container">
              <div>
                {feedback}
                <form className="mt-8 space-y-6" >
                    <div className=" space-y-6 rounded-md shadow-sm">


                        <div>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                required
                                className="form-control form-control-lg"
                                placeholder="Email address"
                                onChange={(e)=>setEmail(e.target.value)}
                                autoComplete="username"
                            />
                        </div>

                        <div>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                className="form-control form-control-lg"
                                placeholder="Password"
                                onChange={(e)=>setPassword(e.target.value)}
                                autoComplete="current-password"
                            />
                        </div>
                    </div>
                    <br/>
                    <div>
                        <button
                            onClick={onLogin}
                            className="btn btn-outline-light btn-lg"
                        >
                            Login
                        </button>
                    </div>

                </form>
                <br/>
                <p className="text-sm text-white text-center">
                    No account yet?{' '}
                    <NavLink to="/signup" className="underline text-tertiary">
                        SignUp
                    </NavLink>
                </p>

            </div>

        </div>
        </header>
      </div>

    )
}

export default Login
