// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";
// import { getMessaging, getToken } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBtKEH15ui2vkRVJFj1FkWAxm5Ra48l-HA",
  authDomain: "robotics-develop.firebaseapp.com",
  projectId: "robotics-develop",
  storageBucket: "robotics-develop.appspot.com",
  messagingSenderId: "541985917575",
  appId: "1:541985917575:web:00bba63cd255fb1c7ac622",
  measurementId: "G-6RP36P3FWC"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
// const analytics = getAnalytics(firebaseApp);
// const messaging = getMessaging(firebaseApp);

// const getMyToken = () => {
//   return getToken(messaging, {vapidKey: 'BLpinN2x5IfxVmeaKvfgG7lp7m6Bu6JQE0FkXrtrWDVrsm5AQxlNQHC-MAvGx3fDetwta9kqNTFFBxgwXhREViE'}).then((currentToken) => {
//     if (currentToken) {
//       // console.log('current token for client: ', currentToken);
//       return(currentToken);
//       // Track the token -> client mapping, by sending to backend server
//       // show on the UI that permission is secured
//     } else {
//       // console.log('No registration token available. Request permission to generate one.');
//       return(null);
//       // shows on the UI that permission is required
//     }
//   }).catch((err) => {
//     // console.log('An error occurred while retrieving token. ', err);
//     // catch error while creating client token
//     return("error");
//   });
// }

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
// export default app;

export {
    firebaseApp
}
