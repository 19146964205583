import React, { useEffect, useState }  from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { addDoc, getDoc, getDocs, doc, setDoc, deleteDoc, updateDoc, deleteField, query, collection, where } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import { CopyBlock, CodeBlock, dracula } from "react-code-blocks";
import "./../App.css";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

function Api() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [uid, setUid] = useState('');
  const [users, setUsers] = useState([]);
  const [tokenCode, setTokenCode] = useState('');
  const [robot, setRobot] = useState("d013791b-aaa-bbbb-cccc-df61dc36a4bc");
  const [robots, setRobots] = useState([]);

  async function getUserSettings(uid){
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()) {
      setTokenCode(docSnap.data().apiToken);
    }
  }

  async function getRobots(userid){
    const q = query(collection(db, "robots"), where("uid", "==", userid));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size > 0){
      let robotList = [];
      querySnapshot.forEach((doc) => {
        var robotX = doc.data();
        robotX.id = doc.id;
        robotList.push(robotX);
      });
      setRobots(robotList);
      setRobot(robotList[0].id)
    } else {
      setRobots([]);
    }
  }

  const updateToken = (userId) => {
    var token = uuidv4();
    setTokenCode(token);
    setDoc(doc(db, "users", userId), {
      apiToken: token,
    }, { merge: true });
  }

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({ hitType: "pageview", page: "/api", title: "API Page" });

      onAuthStateChanged(auth, (user) => {
          if (user) {
            setUid(user.uid);
            getRobots(user.uid);
            getUserSettings(user.uid);
          } else {
            console.log("user is logged out")
            // navigate("/")
          }
        });

  }, [])



  const handleLogout = () => {
      signOut(auth).then(() => {
        navigate("/");
        console.log("Signed out successfully")
      }).catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="App">
      <header className="Content-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">

            </div>
            <div className="col-sm-8">
              <h1 className="brand">API Documentation</h1><br/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
            {robots.length === 0 ? "No robots created yet..." :
            <select onChange={e => setRobot(e.target.value)} className="form-select">
              {robots.map((robot, index) => (
                <option key={index} value={robot.id}>{robot.name}</option>
              ))}
            </select>
            }
            <br/>
            </div>
          </div>

          <div className="d-flex flex-column flex-md-row align-items-start">
            <div className="nav flex-column nav-pills w-50 w-md-auto me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <button className="nav-link active" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="true">Getting Started</button>
              <button className="nav-link" id="v-pills-robot-tab" data-bs-toggle="pill" data-bs-target="#v-pills-robot" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Robot</button>
              <button className="nav-link" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="false">Twist</button>
              <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Python</button>
              <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Bash</button>
            </div>
            <div className="tab-content w-100" id="v-pills-tabContent">
              <div className="tab-pane fade show active" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <p><strong>Getting Started</strong></p>
                <p>Robotics.dev is a ridiculously simple and powerful no-code/low-code AI robotics platform. </p>
                <h4>Your Developer Token: <br/>
                  <span style={{color: "#bd93f9"}}><b>{tokenCode}</b></span><br/><br/>
                  <button
                      className="btn btn-outline-light btn-lg"
                      onClick={() => updateToken(uid)}
                  >
                      Regenerate Token
                  </button>
                </h4>
              </div>
              <div className="tab-pane fade" id="v-pills-robot" role="tabpanel" aria-labelledby="v-pills-robot-tab">
                <p><strong>GET</strong></p>
                <p><strong>https://robotics.dev/robot/:id</strong></p>
                <p>Returns information on a specific robot.</p>

                <CopyBlock
                  text={`curl -X GET https://robotics.dev/robot/${robot}`}
                  language={"bash"}
                  showLineNumbers={false}
                  wrapLongLines
                  theme={dracula}
                />
              </div>
              <div className="tab-pane fade show " id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                <p><strong>POST</strong></p>
                <p><strong>https://robotics.dev/twist</strong></p>
                <p>Send ROS2 twist movement messages to a specific robot.</p>

                <CopyBlock
                  text={`curl -X POST https://robotics.dev/twist -H 'Content-Type: application/json' -d '{"robot": "${robot}","twist": {"linear": {"x": 0.3, "y": 0.0, "z": 0.0}, "angular": {"x": 0.0, "y": 0.0, "z": 0.0}}}'`}
                  language={"bash"}
                  showLineNumbers={false}
                  wrapLongLines
                  theme={dracula}
                />
              </div>
              <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <p><strong>POST</strong></p>
                <p><strong>https://robotics.dev/python</strong></p>
                <p>Deploys and executes python code to a specific robot.</p>

                <CopyBlock
                  text={`curl -X POST https://robotics.dev/python -H 'Content-Type: application/json' -d '{"robot": "${robot}","content": "print('The result of 2 + 2 is:', 2 + 2)"}'`}
                  language={"bash"}
                  showLineNumbers={false}
                  wrapLongLines
                  theme={dracula}
                />
              </div>
              <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                <p><strong>POST</strong></p>
                <p><strong>https://robotics.dev/bash</strong></p>
                <p>Deploys and executes bash code to a specific robot.</p>

                <CopyBlock
                  text={`curl -X POST https://robotics.dev/bash -H 'Content-Type: application/json' -d '{"robot": "${robot}","content": "pip install opencv"}'`}
                  language={"bash"}
                  showLineNumbers={false}
                  wrapLongLines
                  theme={dracula}
                />
              </div>

            </div>
          </div>


        </div>
      </header>
    </div>
  )
}

export default Api;
