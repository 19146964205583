import React, {useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  // sendEmailVerification,
  updateProfile  } from 'firebase/auth';
import { auth, db } from '../firebase';
import { addDoc, getDoc, getDocs, doc, setDoc, deleteDoc, updateDoc, deleteField, query, collection, where } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import "./../App.css";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

const Signup = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [feedback, setFeedback] = useState('');

    React.useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: "/signup", title: "Signup Page" });
    }, [])

    const onSubmit = async (e) => {
      e.preventDefault()
      return

      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;

            updateProfile(auth.currentUser, { displayName: firstName + " " + lastName }).catch(
              (err) => console.log(err)
            )

            var token = uuidv4();

            setDoc(doc(db, "users", user.uid), {
              email: user.email,
              firstName: firstName,
              lastName: lastName,
              apiToken: token
            }, { merge: true })
            .then(
              navigate("/main")
            )

        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setFeedback(errorMessage);
            // ..
        });

        // await sendEmailVerification(auth.currentUser).catch((err) =>
        //   console.log(err)
        // );
    }



  return (

    <div className="App">
      <header className="App-header">
        <div className="container">
            {feedback}
            <form onSubmit={onSubmit} className="mt-8 space-y-10" >
                <div className=" space-y-10 rounded-md shadow-sm">
                    <div>
                        <input
                            label="First name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            name="firstname"
                            type="text"
                            required
                            className="form-control form-control-lg"
                            placeholder="First name"
                        />
                    </div>

                    <div>
                        <input
                            label="Last name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            type="text"
                            name="lastname"
                            className="form-control form-control-lg"
                            placeholder="Last name"
                        />
                    </div>

                    <div>
                        <input
                            type="email"
                            label="Email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="form-control form-control-lg"
                            placeholder="Email address"
                            autoComplete="username"
                        />
                    </div>

                    <div>
                        <input
                            type="password"
                            label="Create password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="form-control form-control-lg"
                            placeholder="Password"
                            autoComplete="new-password"
                        />
                    </div>
                </div>
                <br/>
                <div>
                    <button
                        type="submit"
                        className="btn btn-outline-light btn-lg"
                    >
                        SignUp
                    </button>
                </div>

            </form>

            <br/>
            <p className="text-sm text-white text-center">
                Already have an account?{' '}
                <NavLink to="/login" className="underline text-tertiary">
                    Login
                </NavLink>
            </p>

          </div>

      </header>

    </div>

  )
}

export default Signup
